import {
    DisplayWhenTypes,
    IMobileNavBaseItem,
} from '@/src/common/components/elements/Layout/Navigation/types'
import { firebaseAuth } from '@/config/firebaseClientConfig'
import { signOut as authSignOut } from 'firebase/auth'
import ContactUsText from '@/src/common/components/elements/Text/ContactUsText'

const signOut = async () => {
    await authSignOut(firebaseAuth)
}

export const consumerMobileNavMenuData: IMobileNavBaseItem[] = [
    {
        label: 'Brands',
        url: '/brands',
        testId: 'mobile-brands',
    },
    {
        label: 'Visa gift cards',
        url: '/corporate/visa',
        testId: 'mobile-visa-gift-cards',
    },
    {
        label: 'Corporate/Bulk',
        url: '/corporate',
        testId: 'mobile-bulk-gift-cards',
    },
    {
        label: 'Old buygiftcards.com',
        url: 'https://store.buygiftcards.com',
        testId: 'mobile-old-buygiftcards-com',
    },
    {
        label: '24/7 Help',
        testId: 'mobile-help-section',
        subSection: [
            {
                label: '',
                testId: 'mobile-help-inner',
                children: [
                    {
                        label: 'Visit our help center',
                        gradientText: 'help center',
                        url: 'https://perfectgiftsupport.zendesk.com/hc/en-us',
                        testId: 'mobile-help-center',
                        icon: 'fa-square-question',
                    },
                    {
                        label: 'Activate your card',
                        gradientText: 'Activate',
                        url: '/activate',
                        testId: 'mobile-activate',
                        icon: 'fa-credit-card',
                    },
                    {
                        label: 'Check balance',
                        gradientText: 'balance',
                        url: '/check-balance',
                        testId: 'mobile-check-balance',
                        icon: 'fa-receipt',
                    },
                    {
                        label: 'Track your order',
                        gradientText: 'Track',
                        url: '/order-status',
                        testId: 'mobile-track',
                        icon: 'fa-truck-fast',
                    },
                ],
                formattedContent: ContactUsText(),
            },
        ],
    },
    {
        label: 'Log In',
        displayWhen: DisplayWhenTypes.LOGGED_OUT,
        testId: 'mobile-login-section',
        subSection: [
            {
                label: 'For individuals',
                testId: 'mobile-individuals',
                children: [
                    {
                        label: 'Log in to PerfectGift',
                        url: '/login',
                        testId: 'mobile-login-consumer',
                        icon: 'fa-gift',
                    },
                ],
            },
            {
                label: 'For business',
                testId: 'mobile-business',
                children: [
                    {
                        label: 'Log in to Corporate Portal',
                        url: 'https://corp.perfectgift.com/login',
                        testId: 'mobile-corp-external',
                        icon: 'fa-briefcase',
                    },
                    {
                        label: 'Request a corporate account',
                        url: '/corporate/get-started',
                        testId: 'mobile-request-corp',
                        icon: 'fa-circle-plus',
                    },
                ],
            },
        ],
    },
    {
        label: 'My Account',
        displayWhen: DisplayWhenTypes.LOGGED_IN,
        testId: 'mobile-login-section',
        subSection: [
            {
                label: 'For individuals',
                testId: 'mobile-individuals',
                children: [
                    {
                        label: 'Sign Out',
                        clickAction: () => signOut(),
                        testId: 'mobile-login-consumer',
                        icon: 'fa-right-to-bracket',
                    },
                    {
                        label: 'Dashboard',
                        url: '/account',
                        testId: 'dt-corp',
                        icon: 'fa-home',
                    },
                    {
                        label: 'Received Gifts',
                        url: '/account/gifts/received',
                        testId: 'dt-corp',
                        icon: 'fa-gift',
                    },
                    {
                        label: 'Order History',
                        url: '/account/orders',
                        testId: 'dt-order-history',
                        icon: 'fa-receipt',
                    },
                    {
                        label: 'Manage Account',
                        url: '/account/manage-account',
                        testId: 'dt-corp',
                        icon: 'fa-user',
                    },
                ],
            },
        ],
    },
]
