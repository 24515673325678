import type * as Stitches from '@stitches/react'
import { createStitches } from '@stitches/react'

export type { VariantProps } from '@stitches/react'

export const {
    getCssText,
    styled,
    css,
    globalCss,
    keyframes,
    theme,
    createTheme,
    config,
    reset,
} = createStitches({
    theme: {
        colors: {
            canvas: 'hsl(0 0% 93%)',
            panel: 'white',
            transparentPanel: 'hsl(0 0% 0% / 97%)',
            shadowLight: 'hsl(206 22% 7% / 35%)',
            shadowDark: 'hsl(206 22% 7% / 20%)',

            // PG Design System
            primary: '#A800FF',
            primaryLight: '#F6E6FF',
            secondary: '#2C73FF',
            secondaryLight: '#dde2f3',
            secondaryDark: '#012F8E',
            accentYellow: '#F2CC41',
            accentYellowLight: '#FFF7DA',
            accentAqua: '#8FEFBF',
            blue: '#346FEF',
            white: '#FFF',
            light: '#EDEEEF',
            lightest: '#F8F8F8',
            mediumGray: '#B7BBC1',
            dark: '#4B5563',
            darkest: '#244f31',
            error: '#D64045',
            errorLight: '#FFC8CA',
            info: '#eff6ff',
            infoIcon: '#60A5FA',
            infoDarkText: '#1E40AF',
            success: '#157F1F',
            successGreenLight: '#D4FFD9',
            warning: '#DEB00C',
            warningBrown: '#92400E',
            greyText: '#828993',

            pgPrimary: '#A800FF',
            pgSecondary: '#2C73FF',
        },
        fonts: {
            default: `'Visby', sans-serif`,
            handWritten: `'Shadows Into Light', cursive, serif`,
            handWryttenGreetingCard: `'Handwrytten', cursive, serif`,
        },
        space: {
            1: '5px',
            2: '10px',
            3: '15px',
            4: '20px',
            5: '25px',
            6: '35px',
            7: '45px',
            8: '65px',
            9: '80px',
        },
        sizes: {
            1: '5px',
            2: '10px',
            3: '15px',
            4: '20px',
            5: '25px',
            6: '35px',
            7: '45px',
            8: '65px',
            9: '80px',
            default: '1440px',
            defaultMax: '90vw',
            fullWidth: '100vw',
        },
        fontSizes: {
            1: '12px',
            2: '14px',
            3: '16px',
            4: '17px',
            5: '20px',
            6: '25px',
            7: '30px',
            8: '40px',
            9: '55px',
        },
        radii: {
            1: '4px',
            2: '6px',
            3: '8px',
            4: '12px',
            round: '50%',
            pill: '9999px',
        },
        shadows: {
            primary: '#A800FF',
        },
    },

    utils: {
        p: (value: Stitches.PropertyValue<'padding'>) => ({
            padding: value,
        }),
        pt: (value: Stitches.PropertyValue<'paddingTop'>) => ({
            paddingTop: value,
        }),
        pr: (value: Stitches.PropertyValue<'paddingRight'>) => ({
            paddingRight: value,
        }),
        pb: (value: Stitches.PropertyValue<'paddingBottom'>) => ({
            paddingBottom: value,
        }),
        pl: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
            paddingLeft: value,
        }),
        px: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
            paddingLeft: value,
            paddingRight: value,
        }),
        py: (value: Stitches.PropertyValue<'paddingTop'>) => ({
            paddingTop: value,
            paddingBottom: value,
        }),
        m: (value: Stitches.PropertyValue<'margin'>) => ({
            margin: value,
        }),
        mt: (value: Stitches.PropertyValue<'marginTop'>) => ({
            marginTop: value,
        }),
        mr: (value: Stitches.PropertyValue<'marginRight'>) => ({
            marginRight: value,
        }),
        mb: (value: Stitches.PropertyValue<'marginBottom'>) => ({
            marginBottom: value,
        }),
        ml: (value: Stitches.PropertyValue<'marginLeft'>) => ({
            marginLeft: value,
        }),
        mx: (value: Stitches.PropertyValue<'marginLeft'>) => ({
            marginLeft: value,
            marginRight: value,
        }),
        my: (value: Stitches.PropertyValue<'marginTop'>) => ({
            marginTop: value,
            marginBottom: value,
        }),

        ta: (value: Stitches.PropertyValue<'textAlign'>) => ({
            textAlign: value,
        }),

        fd: (value: Stitches.PropertyValue<'flexDirection'>) => ({
            flexDirection: value,
        }),
        fw: (value: Stitches.PropertyValue<'flexWrap'>) => ({
            flexWrap: value,
        }),

        ai: (value: Stitches.PropertyValue<'alignItems'>) => ({
            alignItems: value,
        }),
        ac: (value: Stitches.PropertyValue<'alignContent'>) => ({
            alignContent: value,
        }),
        jc: (value: Stitches.PropertyValue<'justifyContent'>) => ({
            justifyContent: value,
        }),
        as: (value: Stitches.PropertyValue<'alignSelf'>) => ({
            alignSelf: value,
        }),
        fg: (value: Stitches.PropertyValue<'flexGrow'>) => ({
            flexGrow: value,
        }),
        fs: (value: Stitches.PropertyValue<'flexShrink'>) => ({
            flexShrink: value,
        }),
        fb: (value: Stitches.PropertyValue<'flexBasis'>) => ({
            flexBasis: value,
        }),

        bc: (value: Stitches.PropertyValue<'backgroundColor'>) => ({
            backgroundColor: value,
        }),

        br: (value: Stitches.PropertyValue<'borderRadius'>) => ({
            borderRadius: value,
        }),
        btrr: (value: Stitches.PropertyValue<'borderTopRightRadius'>) => ({
            borderTopRightRadius: value,
        }),
        bbrr: (value: Stitches.PropertyValue<'borderBottomRightRadius'>) => ({
            borderBottomRightRadius: value,
        }),
        bblr: (value: Stitches.PropertyValue<'borderBottomLeftRadius'>) => ({
            borderBottomLeftRadius: value,
        }),
        btlr: (value: Stitches.PropertyValue<'borderTopLeftRadius'>) => ({
            borderTopLeftRadius: value,
        }),

        bs: (value: Stitches.PropertyValue<'boxShadow'>) => ({
            boxShadow: value,
        }),

        lh: (value: Stitches.PropertyValue<'lineHeight'>) => ({
            lineHeight: value,
        }),

        ox: (value: Stitches.PropertyValue<'overflowX'>) => ({
            overflowX: value,
        }),
        oy: (value: Stitches.PropertyValue<'overflowY'>) => ({
            overflowY: value,
        }),

        pe: (value: Stitches.PropertyValue<'pointerEvents'>) => ({
            pointerEvents: value,
        }),
        us: (value: Stitches.PropertyValue<'userSelect'>) => ({
            WebkitUserSelect: value,
            userSelect: value,
        }),

        userSelect: (value: Stitches.PropertyValue<'userSelect'>) => ({
            WebkitUserSelect: value,
            userSelect: value,
        }),

        size: (value: Stitches.PropertyValue<'width'>) => ({
            width: value,
            height: value,
        }),

        appearance: (value: Stitches.PropertyValue<'appearance'>) => ({
            WebkitAppearance: value,
            appearance: value,
        }),
        backgroundClip: (value: Stitches.PropertyValue<'backgroundClip'>) => ({
            WebkitBackgroundClip: value,
            backgroundClip: value,
        }),

        // A property to apply linear gradient
        linearGradient: (value: string) => ({
            backgroundImage: `linear-gradient(${value})`,
        }),
        navDropdownShadow: () => ({
            boxShadow: `0px 10px 25px -10px rgba(1, 47, 142, 0.5)`,
        }),
    },
    media: {
        sm: '(min-width: 520px)',
        md: '(min-width: 900px)',
        lg: '(min-width: 1200px)',
        xl: '(min-width: 1800px)',
        motion: '(prefers-reduced-motion)',
        hover: '(any-hover: hover)',
        dark: '(prefers-color-scheme: dark)',
        light: '(prefers-color-scheme: light)',
    },
})

export type CSS = Stitches.CSS<typeof config>
